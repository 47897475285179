import moment from "moment";
import pad from "pad-left";

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

export const calendarFormat = "MMMM Do";

export const logFormat = () => {
  return moment().format("YYYYMMDD-HHmmss");
};

export const enFormat = (momentObj: any) => {
  return momentObj.format("YYYYMMDD");
};

export const withTimeFormat = (nowTimeFormatted: any) => {
  return moment(nowTimeFormatted, "YYYYMMDD_HHmmss").format(
    "Do MMMM YYYY HH:mm"
  );
};

export const now = () => {
  return moment().format("YYYYMMDD");
};

export const nowTime = () => {
  return moment().format("YYYYMMDD_HHmmss");
};

export const csDayMonth = (day: any, month: string) => {
  return moment([
    moment().year(),
    moment.localeData("cs").monthsParse(month, "MMMM", false),
    day,
  ]).format("YYYYMMDD");
};

export const enParse = (str: string) => {
  return moment(str).format("YYYYMMDD");
};

export const isMonth = (str: string) => {
  if (moment.localeData("cs").monthsParse(str, "MMMM", false) === undefined) {
    return false;
  } else {
    return true;
  }
};

export const dayOnly = (str: string) => {
  return moment().format("YYYYMM") + pad(str, 2, "0");
};

export const dateDotsDayOnly = (str: string) => {
  const date = moment(str, "DD.MM");
  const now = moment();
  if (date.month() < now.month()) {
    date.year(now.year() + 1);
  }
  return date.format("YYYYMMDD");
};

export const dateDots = (str: string) => {
  return moment(str, "DD.MM.YYYY").format("YYYYMMDD");
};

export const dateDashes = (str: string) => {
  return moment(str, "YYYY-MM-DD").format("YYYYMMDD");
};

export const dateSlashes = (str: string) => {
  return moment(str, "DD/MM/YYYY").format("YYYYMMDD");
};

export const dateSlashesShort = (str: string) => {
  return moment(str, "DD/MM").format("YYYYMMDD");
};

export const day = (str: string) => {
  return moment(str, "YYYYMMDD").day();
};

export const monthYear = (str: string) => {
  return moment(str, "YYYYMMDD").format("MMMM Do");
};

export const monthYearShort = (str: string) => {
  return moment(str, "YYYYMMDD").format("MMM D");
};

export const weekday = (str: string) => {
  return moment(str, "YYYYMMDD").format("dddd");
};

export const weekdayShort = (str: string) => {
  return moment(str, "YYYYMMDD").format("ddd");
};

export const weekdayColor = (str: string) => {
  switch (day(str)) {
    case 0:
      return "Red";
    case 1:
      return "Yellow";
    case 2:
      return "HotPink";
    case 3:
      return "Green";
    case 4:
      return "Orange";
    case 5:
      return "LightBlue";
    case 6:
      return "Purple";
  }
  return "";
};
