import {
  monthYear,
  monthYearShort,
  weekday,
  weekdayColor,
  weekdayShort,
} from "../../lib/dates";

type EventProps = {
  place_slug: string;
  place_name: string;
  place_link: string;
  date: string;
  title: string;
  link?: string;
  display_place: boolean;
  width: number;
};

const getDayColor = (day: string) => ({ color: weekdayColor(day) });

const EventItem = ({
  place_name,
  place_link,
  date,
  title,
  link,
  display_place,
  width,
}: EventProps) => (
  <tr>
    {(() => {
      if (display_place) {
        return (
          <td className="colPlaceShow">
            <a href={place_link} target="_blank" rel="noreferrer">
              {place_name}
            </a>
          </td>
        );
      }
      return null;
    })()}
    <td className="colDate">
      <span style={getDayColor(date)}>
        {width >= 480 ? weekday(date) : weekdayShort(date)}
      </span>
      &nbsp;
      {width >= 480 ? monthYear(date) : monthYearShort(date)}
    </td>
    <td className="colEvent">
      {(() => {
        if (link) {
          return (
            <a href={link} target="_blank" rel="noreferrer">
              {title}
            </a>
          );
        } else {
          return title;
        }
      })()}
    </td>
  </tr>
);

export default EventItem;
