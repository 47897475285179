import { useState } from "react";
import Select from "react-select";

type MultiSelectFieldProps = {
  places: any[];
  onSelect: (arr: any[]) => void;
};

type MultiSelectFieldState = {
  value: any[];
};

const MultiSelectField = ({ places, onSelect }: MultiSelectFieldProps) => {
  const [state, setState] = useState<MultiSelectFieldState>({
    value: [],
  });

  const adjustPlaces = () =>
    places.map((place) => ({
      label: place.name,
      value: place.slug,
    }));

  const handleSelectChange = (value: any) => {
    setState({ ...state, value });
    if (value) {
      onSelect(value.map((p: any) => p.value));
    } else {
      onSelect([]);
    }
  };

  return (
    <div className="placeSelect">
      <Select
        isMulti={true}
        isDisabled={false}
        value={state.value}
        placeholder="Select your favourite places"
        options={adjustPlaces()}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default MultiSelectField;
