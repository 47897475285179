import firebase from "firebase/app";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDSbC3k_ZvWqclyxzRhNRj7HubOhCbidpA",
  authDomain: "praguerocks-a1134.firebaseapp.com",
  databaseURL: "https://praguerocks-a1134.firebaseio.com",
  projectId: "praguerocks-a1134",
  storageBucket: "praguerocks-a1134.appspot.com",
  messagingSenderId: "289786012140",
  appId: "1:289786012140:web:2a203379becbed8f9260c9",
  measurementId: "G-CTKXP6WLJT",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
}

export const getDocs = async (collection: string, orderBy?: string) => {
  const result: any[] = [];
  const documents = orderBy
    ? await db.collection(collection).orderBy(orderBy, "asc").get()
    : await db.collection(collection).get();

  documents.forEach(function (doc: any) {
    // doc.data() is never undefined for query doc snapshots
    result.push(doc.data());
  });
  return result;
};

export { firebase, db };
