import Header from "./header";
import Footer from "./footer";
import Multiselect from "./multiselect";
import Switch from "react-toolbox/lib/switch";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";

import { useEffect, useState } from "react";

import Events from "./events";
import {
  filterCurrent,
  filterPlacesWithEvents,
  filterSelected,
  getPlacesBySlug,
  sortByPlace,
} from "./utils";
import { calendarFormat, withTimeFormat } from "../../lib/dates";
import moment from "moment";

import { getDocs } from "../firebase";
import {
  EndDate,
  Event,
  EventsByPlace,
  Place,
  PlacesBySlug,
  StartDate,
  ViewPlace,
} from "./dto";

import { WaveLoading } from "styled-spinkit";

type AppState = {
  selectedPlaces?: ViewPlace[];
  eventsInitial: Event[];
  places: Place[];
  placesBySlug: PlacesBySlug;
  focusedInput: any;
  displayByPlace: boolean;
  lastUpdate?: string;
};

type EventsState = {
  events: Event[];
  eventsByPlace: EventsByPlace;
};

type DatesState = {
  startDate?: StartDate;
  endDate?: EndDate;
};

export const App = () => {
  const [state, setState] = useState<AppState>({
    eventsInitial: [],
    places: [],
    placesBySlug: {},
    focusedInput: null,
    displayByPlace: false,
  });

  const [dates, setDates] = useState<DatesState>({});
  const [events, setEvents] = useState<EventsState>({
    events: [],
    eventsByPlace: {},
  });

  const updateEvents = (
    startDate?: StartDate,
    endDate?: EndDate,
    selectedPlaces?: ViewPlace[]
  ) => {
    const events = filterSelected(
      filterCurrent(state.eventsInitial),
      startDate,
      endDate,
      selectedPlaces
    );
    const eventsByPlace = sortByPlace(events);

    setEvents({
      events,
      eventsByPlace,
    });
  };

  const onPlaceSelected = (selectedPlaces: ViewPlace[]) => {
    setState({
      ...state,
      selectedPlaces,
    });
    updateEvents(dates.startDate, dates.endDate, selectedPlaces);
  };

  const onDateSelected = (startDate?: StartDate, endDate?: EndDate) => {
    setDates({
      startDate,
      endDate,
    });

    if (startDate && endDate) {
      updateEvents(startDate, endDate, state.selectedPlaces);
    }
  };

  const onDisplaySwitched = () => {
    setState({
      ...state,
      displayByPlace: !state.displayByPlace,
    });
  };

  const initData = async () => {
    const events = filterCurrent(await getDocs("events", "date"));
    const eventsByPlace = sortByPlace(events);
    const places = filterPlacesWithEvents(
      (await getDocs("places")) as Place[],
      eventsByPlace
    );
    const placesBySlug = getPlacesBySlug(places);

    setState({
      eventsInitial: events,
      places,
      placesBySlug,
      lastUpdate: undefined,
      focusedInput: null,
      displayByPlace: false,
    });

    setEvents({
      events,
      eventsByPlace,
    });

    setDates({
      startDate: moment(),
      endDate: null,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div>
      {state.eventsInitial.length > 0 ? (
        <div>
          <Header />
          <Multiselect places={state.places} onSelect={onPlaceSelected} />
          <DateRangePicker
            displayFormat={calendarFormat}
            startDate={dates.startDate ?? null}
            endDate={dates.endDate ?? null}
            onDatesChange={({ startDate, endDate }) =>
              onDateSelected(startDate, endDate)
            }
            focusedInput={state.focusedInput}
            onFocusChange={(focusedInput) =>
              setState({ ...state, focusedInput })
            }
            startDateId="your_unique_start_date_id"
            endDateId="your_unique_end_date_id"
          />
          {/* @ts-ignore:next-line */}
          <Switch
            checked={state.displayByPlace}
            label="Display by place"
            onChange={onDisplaySwitched}
            className="DisplayByPlaceSwitch"
          />
          <Events
            events={events.events}
            eventsByPlace={events.eventsByPlace}
            placesBySlug={state.placesBySlug}
            displayByPlace={state.displayByPlace}
          />
          {state.lastUpdate && (
            <Footer lastUpdate={withTimeFormat(state.lastUpdate)} />
          )}
        </div>
      ) : (
        <WaveLoading color="white" size={60} />
      )}
    </div>
  );
};

export default App;
