import { ReactElement, useState } from "react";
import hash from "string-hash";
import { Event, EventsByPlace, PlacesBySlug } from "./dto";
import EventItem from "./eventItem";

type EventsProps = {
  events: Event[];
  eventsByPlace: EventsByPlace;
  placesBySlug: PlacesBySlug;
  displayByPlace: boolean;
};

const Events = ({
  events,
  eventsByPlace,
  placesBySlug,
  displayByPlace,
}: EventsProps) => {
  const [width] = useState(window.innerWidth);

  const getEvents = (eventsArray: Event[], displayPlace: boolean) => {
    return eventsArray.map((event: Event) => {
      return (
        <EventItem
          date={event.date}
          key={hash(event.place_slug + event.date + event.title)}
          title={event.title}
          link={event.link}
          place_slug={event.place_slug}
          place_name={placesBySlug[event.place_slug].name}
          place_link={placesBySlug[event.place_slug].link}
          display_place={displayPlace}
          width={width}
        />
      );
    });
  };

  const getEventsByPlace = (): ReactElement[] => {
    let result = [] as ReactElement[];
    for (let key in eventsByPlace) {
      result.push(
        <table className="eventsTable" key={"events-" + key}>
          <thead>
            <tr>
              <th className="colPlaceShow placeTitle">
                {placesBySlug[key].name}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="colDate">Date</td>
              <td className="colEvent">Name</td>
            </tr>
            {getEvents(eventsByPlace[key], false)}
          </tbody>
        </table>
      );
    }
    return result;
  };

  const getEventsByDate = (): ReactElement => {
    return (
      <table className="eventsTable">
        <thead>
          <tr>
            <th className="colPlaceShow">Place</th>
            <th className="colDate">Date</th>
            <th className="colEvent">Name</th>
          </tr>
        </thead>
        <tbody>{getEvents(events, true)}</tbody>
      </table>
    );
  };

  const displayEvents = (): ReactElement | ReactElement[] => {
    if (displayByPlace) {
      return getEventsByPlace();
    } else {
      return getEventsByDate();
    }
  };

  return <div>{displayEvents()}</div>;
};

export default Events;
