import { enFormat, now } from "../../lib/dates";
import {
  EndDate,
  Event,
  EventsByPlace,
  Place,
  PlacesBySlug,
  StartDate,
  ViewPlace,
} from "./dto";

export const filterSelected = (
  events: any,
  startDate?: StartDate,
  endDate?: EndDate,
  selectedPlaces?: ViewPlace[]
) =>
  events.filter((item: any) => {
    if (selectedPlaces && selectedPlaces.length) {
      if (!selectedPlaces.includes(item.place_slug)) {
        return false;
      }
    }
    if (startDate && item.date <= enFormat(startDate)) {
      return false;
    }
    if (endDate && item.date >= enFormat(endDate)) {
      return false;
    }
    return true;
  });

export const filterCurrent = (events: Event[]): Event[] => {
  let result = [] as Event[];
  events.forEach(function (currentValue: any) {
    if (currentValue.date >= now()) {
      result.push(currentValue);
    }
  });
  return result;
};

export const sortByPlace = (events: Event[]): EventsByPlace => {
  let result = {} as EventsByPlace;
  events.forEach(function (currentValue: Event) {
    if (!result[currentValue.place_slug]) {
      result[currentValue.place_slug] = [];
    }
    result[currentValue.place_slug].push(currentValue);
  });
  return result;
};

export const getPlacesBySlug = (places: Place[]): PlacesBySlug =>
  places.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.slug]: curr,
    }),
    {}
  );

export const filterPlacesWithEvents = (
  places: Place[],
  eventsByPlace: EventsByPlace
) => places.filter((place) => Object.keys(eventsByPlace).includes(place.slug));
